<template>
  <v-app>
    <side-navigation
      :expanded="drawer"
      :items="sideNavigation"
    ></side-navigation>

    <!-- Toolbar -->
    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>SAC-Tourenverwaltung: Administration</v-toolbar-title>
    </v-app-bar>

    <!-- Main Content -->
    <v-main>
      <v-fade-transition mode="out-in">
        <!-- NOTE: The scroll behaviour depends on the transition duration! -->
        <router-view />
      </v-fade-transition>
    </v-main>

    <v-snackbar
      v-for="(notification, index) in notifications"
      :key="index"
      :top="true"
      :right="true"
      :color="notification.type"
      v-model="notification.show"
      :style="{ top: 8 + index * 56 + 'px' }"
    >
      <span :class="{ 'black--text': notification.type === 'warning' }">{{
        notification.text
      }}</span>
    </v-snackbar>

    <app-footer />
    <update-notification />

    <v-dialog v-if="canSwitchUser" v-model="showUserSwitch" width="300">
      <user-switch-card @close="showUserSwitch = false" />
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from 'pinia'
import { handleApiError } from '@/utils.js'

import UpdateNotification from '@/components/pwa/UpdateNotification'
import UserSwitchCard from '@/components/UserSwitchCard'
import SideNavigation from '@/components/SideNavigation'
import AppFooter from '@/components/Footer'
import { usePersonStore } from '@/stores/person'
import { useUserStore } from '@/stores/user'
import { useNotificationStore } from '@/stores/notification'
import { useEventStore } from '@/stores/event'

export default {
  name: 'Main',
  components: { UpdateNotification, UserSwitchCard, SideNavigation, AppFooter },
  props: {},
  data() {
    return {
      drawer: false,
      showUserSwitch: false,
      version: process.env.VUE_APP_VERSION,
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    sideNavigation() {
      return [
        { type: 'item', title: 'Tourenverwaltung', icon: 'mdi-arrow-left', props: { to: { name: 'Dashboard' }, exact: true } },
        { type: 'divider' },
        { type: 'item', title: 'Übersicht', icon: 'mdi-view-dashboard', props: { to: { name: 'AdminDashboard' }, exact: true } },
        { type: 'item', title: 'Tourengruppen', icon: 'mdi-account-group-outline', props: { to: { name: 'TouringGroups' }, exact: true } },
        { type: 'item', title: 'Tourenarten', icon: 'mdi-hiking', props: { to: { name: 'EventTypes' }, exact: true } },
        { type: 'item', title: 'Tools', icon: 'mdi-wrench', props: { to: { name: 'AdminTools' } } },
      ]
    },
    canSwitchUser() {
      return !!localStorage.getItem('realUser')
    },
    ...mapState(useNotificationStore, ['notifications']),
    ...mapState(useUserStore, { user: store => store }),
  },
  mounted() {
    useUserStore().fetchUser().catch(e => handleApiError(e, 'Fehler beim Laden der Benutzerdaten'))
    useEventStore().initialize().catch(e => handleApiError(e, 'Fehler beim Laden der Touren Daten'))
    usePersonStore().fetchPersons().catch(e => handleApiError(e, 'Fehler beim Laden der Personen Daten'))


    this.drawer = this.$vuetify.breakpoint.mdAndUp
  },
  methods: {},
}
</script>
